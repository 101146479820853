import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Grid } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddIngredientMutation,
  useUpdateIngredientMutation,
  useGetIngredientByIdQuery,
} from "../../../../../../store/apis/IngredientAPIs";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { NUTRITION_INFO_FIELDS } from "./forms/forms.constants";
import { BackButton } from "components/shared/BackButton";

function AddIngredient() {
  const currentLocation = useLocation();

  const [
    addIngredient,
    {
      isError: addError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addErrorData,
    },
  ] = useAddIngredientMutation();
  const [
    updateIngredient,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorData,
    },
  ] = useUpdateIngredientMutation();

  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentIngredientId = searchParams.get("id");
  const newTab = searchParams.get("newTab");
  const menuId = searchParams.get("menuId");
  const errorData = addErrorData || updateErrorData;

  const isEdit = !!currentIngredientId;

  const {
    data: currentIngredient,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetIngredientByIdQuery(currentIngredientId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentIngredient) {
      reset(currentIngredient);
    }
  }, [currentIngredient]);

  useEffect(() => {
    if (isError && errorData) {
      setErrorsForProperties({
        setError,
        responseError: errorData,
        setValidationSummary: null,
      });
    }
  }, [isError, errorData]);

  useEffect(() => {
    if (isSuccess) {
      if (
        currentLocation.pathname ===
        "/plateFul/Menus/MissingVendors/editIngredient"
      ) {
        navigate(`/plateFul/Menus/MissingVendor?menuId=${menuId}`);
      } else navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateIngredient(data);
      return;
    }
    addIngredient(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          {currentLocation.pathname !==
            "/plateFul/Menus/MissingVendors/editIngredient" && !newTab ? (
            <Box>
              <BackButton
                text="Back to Ingredients"
                To={"/admin/recipes/ingredients"}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              padding: "8px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                // flexDirection: "column",
              }}
            >
              <GeneratedForm
                control={control}
                list={NUTRITION_INFO_FIELDS}
                errors={errors}
              />

              <Grid item md={12}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Grid>
              {isError ? (
                <Typography
                  sx={{
                    color: "error",
                  }}
                >
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default AddIngredient;
