import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";

export const storageTypes = [
  { id: "", label: "" },
  { id: "Cooler", label: "Cooler" },
  { id: "Dry", label: "Dry" },
  { id: "Frozen", label: "Frozen" },
];

export const NUTRITION_INFO_FIELDS = [
  {
    size: 12,
    props: {
      label: "Ingredient Name",
      placeholder: "Ingredient Name",
      id: "name",
      name: "name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Ingredient Name"),
    },
  },
  {
    size: 12,
    props: {
      label: "CCP Thawing Info",
      placeholder: "CCP Thawing Info",
      id: "ccpThawingInformation",
      name: "ccpThawingInformation",
    },
  },
  {
    size: 3,
    props: {
      label: "Nutrient Calculation Qty",
      placeholder: "",
      id: "nutrientCalculationQuantity",
      name: "nutrientCalculationQuantity",
      type: "number",
      default: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Nutrient Calculation Qty"),
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      name: "nutrientCalculationUnitId",
      id: "nutrientCalculationUnitId",
      label: "Nutrient Calculation Unit",
    },
    size: 3,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getSelectUnits",
  },
  {
    size: 3,
    props: {
      label: "USDA NDB Number",
      placeholder: "USDA NDB Number",
      id: "usdaNdbNumber",
      name: "usdaNdbNumber",
    },
  },
  {
    props: {
      name: "shoppingListUnitId",
      id: "shoppingListUnitId",
      label: "Shopping List Unit",
    },
    size: 3,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getSelectUnits",
  },
  {
    props: {
      name: "ingredientCategoryId",
      id: "ingredientCategoryId",
      label: "Category",
    },
    size: 3,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getIngredientCategories",
    validationProps: {
      required: REQUIRED_ERROR("Category"),
    },
  },
  {
    props: {
      name: "storageArea",
      id: "storageArea",
      label: "Storage Area",
    },
    size: 3,
    options: storageTypes,
    type: FORM_TYPES.Select,
  },
  {
    props: {
      label: "Pureed recipe",
      name: "isRecipe",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      label: "Ignore on Shopping List",
      name: "ignoreOnShoppingList",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      text: "KCal",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "kCal",
      name: "kCal",
      type: "number",
      unit: "",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Protein (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "protein",
      name: "protein",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Fiber (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "fiber",
      name: "fiber",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Fat (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "fat",
      name: "fat",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Saturated Fat (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "saturatedFat",
      name: "saturatedFat",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Trans Fat (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "transFat",
      name: "transFat",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Cholesterol (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "cholesterol",
      name: "cholesterol",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Carbohydrates (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "carbohydrates",
      name: "carbohydrates",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Vitamin A (RAE)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "vitaminA",
      name: "vitaminA",
      type: "number",
      unit: "RAE",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Vitamin C (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "vitaminC",
      name: "vitaminC",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Thiamine - B1 (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "thiamine",
      name: "thiamine",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Riboflavin - B2 (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "riboflavin",
      name: "riboflavin",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Niacin - B3 (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "niacin",
      name: "niacin",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Folate, DFE (mcg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "folate",
      name: "folate",
      type: "number",
      unit: "mcg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Sodium - Na (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "sodium",
      name: "sodium",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Sugars, Total (gm)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "sugars",
      name: "sugars",
      type: "number",
      unit: "gm",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Calcium - Ca (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "calcium",
      name: "calcium",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Potassium - K (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "potassium",
      name: "potassium",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Phosphorus - P (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "phosporous",
      name: "phosporous",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Iron - Fe (mg))",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "iron",
      name: "iron",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Magnesium (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "magnesium",
      name: "magnesium",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Zinc (mg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "zinc",
      name: "zinc",
      type: "number",
      unit: "mg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Vitamin B-12 (mcg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "vitaminB12",
      name: "vitaminB12",
      type: "number",
      unit: "mcg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },
  {
    props: {
      text: "Vitamin B-6 (mcg)",
      textAlign: "right",
      paddingTop: 2,
    },
    size: 2,
    type: FORM_TYPES.Text,
  },
  {
    size: 2,
    props: {
      id: "vitaminB6",
      name: "vitaminB6",
      type: "number",
      unit: "mcg",
      defaultValue: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
    },
  },

  {
    size: 12,
    props: {
      name: "notes",
      id: "notes",
      label: "Notes",
      multiline: true,
      rows: 4,
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Notes", 5000),
    },
  },
];

export const NutrientInfoFields = {
  nutrients: [
    {
      size: 6,
      props: {
        label: "KCal",
        id: "kCal",
        name: "kCal",
        unitlabel: "",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Protein",
        id: "protein",
        name: "protein",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Fiber",
        id: "fiber",
        name: "fiber",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Fat",
        id: "fat",
        name: "fat",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Saturated Fat",
        id: "saturatedFat",
        name: "saturatedFat",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Trans Fat",
        id: "transFat",
        name: "transFat",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Cholesterol",
        id: "cholesterol",
        name: "cholesterol",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Carbohydrates",
        id: "carbohydrates",
        name: "carbohydrates",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Vitamin A",
        id: "vitaminA",
        name: "vitaminA",
        unitlabel: "RAE",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Vitamin C",
        id: "vitaminC",
        name: "vitaminC",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Thiamine (B1)",
        id: "thiamine",
        name: "thiamine",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Riboflavin (B2)",
        id: "riboflavin",
        name: "riboflavin",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Niacin (B3)",
        id: "niacin",
        name: "niacin",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Folate, DFE",
        id: "folate",
        name: "folate",
        unitlabel: "mcg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Sodium (Na)",
        id: "sodium",
        name: "sodium",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Sugars, Total",
        id: "sugars",
        name: "sugars",
        unitlabel: "gm",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Calcium (Ca)",
        id: "calcium",
        name: "calcium",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Potassium (K)",
        id: "potassium",
        name: "potassium",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Phosphorus (P)",
        id: "phosporous",
        name: "phosporous",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Iron (Fe)",
        id: "iron",
        name: "iron",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Magnesium",
        id: "magnesium",
        name: "magnesium",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Zinc",
        id: "zinc",
        name: "zinc",
        unitlabel: "mg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Vitamin (B-12)",
        id: "vitaminB12",
        name: "vitaminB12",
        unitlabel: "mcg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
    {
      size: 6,
      props: {
        label: "Vitamin B-6",
        id: "vitaminB6",
        name: "vitaminB6",
        unitlabel: "mcg",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        min: {
          value: 0,
          message: "Value must be greater than 0.",
        },
      },
    },
  ],
};
