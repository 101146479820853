import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import {
  useAddFacilityDietMutation,
  useAvailableFacilityDietSelectListQuery,
  useFacilityDietByIdQuery,
  useUpdateFacilityDietMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { INVALID_NAME_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddCommunityDiet() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const fdId = searchParams.get("fdId");

  const [newDiets, setNewDiets] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({});
  const [isChange, setIsChange] = React.useState(false);
  const {
    data: availableDiets,
    isLoading: availableDietsLoading,
    isSuccess: availableDietsSuccess,
  } = useAvailableFacilityDietSelectListQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const {
    data: currentFD,
    isLoading: currentFDLoading,
    isSuccess: currentFDSuccess,
  } = useFacilityDietByIdQuery(fdId, {
    refetchOnMountOrArgChange: true,
    skip: !fdId,
  });

  const navigate = useNavigate();

  const [addFacilityDiet, { isLoading: addSubmitting, isSuccess: addSuccess }] =
    useAddFacilityDietMutation();

  const [
    updateFacilityDiet,
    { isLoading: updateSubmitting, isSuccess: updateSuccess },
  ] = useUpdateFacilityDietMutation();

  const isSubmitting = addSubmitting || updateSubmitting;
  const isSuccess = addSuccess || updateSuccess;

  const isEdit = !!fdId;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    if (currentFDSuccess && !currentFDLoading) {
      setNewDiets([
        ...newDiets,
        { id: currentFD?.dietId, label: currentFD?.commonDietName },
      ]);
      reset(currentFD);
      setInitialValues(currentFD);
    }
  }, [currentFDSuccess, currentFD, currentFDLoading]);

  useEffect(() => {
    if (availableDietsSuccess && !availableDietsLoading) {
      if (isEdit) {
        setNewDiets([
          ...availableDiets,
          { id: currentFD?.dietId, label: currentFD?.commonDietName },
        ]);
      } else {
        setNewDiets([...availableDiets]);
      }
    }
  }, [availableDietsSuccess, availableDiets, availableDietsLoading, currentFD]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.facilityId = id;

    if (isEdit) {
      updateFacilityDiet(data);
      return;
    }
    addFacilityDiet(data);
  };

  const isEditLoading = isEdit && currentFDLoading;

  const selectedDietId = watch("dietId");

  useEffect(() => {
    if (selectedDietId > 0) {
      const selectedDiet = availableDiets?.find(
        (diet) => diet.id === selectedDietId
      );
      if (selectedDiet) {
        setValue("dietName", selectedDiet.label);
      }
    }
  }, [selectedDietId]);

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            watch={watch}
            hasChange={isChange}
            setHasChange={setIsChange}
            getValues={getValues}
            dirtyFields={dirtyFields}
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <BaseSelect
                name="dietId"
                id="dietId"
                label="Common Diet Name"
                control={control}
                validationProps={{
                  required: REQUIRED_ERROR("Common Diet Name"),
                }}
                errors={errors}
                options={newDiets || []}
                loading={availableDietsLoading}
              />
            </Grid>
            <Grid item md={6} />

            <Grid item md={6}>
              <BaseInput
                name="dietName"
                id="dietName"
                label="Community Diet Name"
                validationProps={{
                  required: REQUIRED_ERROR("Community Diet Name"),
                  pattern: INVALID_NAME_VALIDATION("Common diet name"),
                }}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item md={6} />

            <Grid item md={6}>
              <BaseInput
                name="sortOrder"
                id="sortOrder"
                label="Sort Order"
                control={control}
                errors={errors}
                defaultValue={1}
                validationProps={{
                  required: REQUIRED_ERROR("Sort Order"),
                  maxLength: {
                    value: 3,
                    message: "please use 3 char or less",
                  },
                }}
                type={"number"}
              />
            </Grid>
            <Grid item md={6} />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
